import React, { useState } from 'react';
import {Button, Col, Row, Card} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import Swal from "sweetalert2";
import {HrTimeSheetServicePromiseClient} from "../../protobuf/pb/service_upload_file_grpc_web_pb";
import {ToolInputRequest} from "../../protobuf/pb/service_upload_file_pb";
import {RpcError} from "grpc-web";
import useToken from "../App/useToken";
import {saveByteArray} from "../../services/uploadFileStream";


export default function PreProcessTool() {
    const client = new HrTimeSheetServicePromiseClient(process.env.REACT_APP_PROXY_HOST, null, null);
    const [fileFingerprint,setFileFingerprint] = useState(null);
    const { token, setToken } = useToken();
    const [loadings, setLoadings] = useState([]);
    const metadata = {"authorization": "Bearer " + token};
    const handleError = (error) => {
        return  Swal.fire({
            title: 'Error',
            text: error,
            icon: 'error',
            confirmButtonText: 'OK',
        });
    };

    const getBuffer = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function () {

                const arrayBuffer = reader.result;
                const data = new Uint8Array(arrayBuffer);
                resolve(data)

            }
            reader.onerror = () => {
                reject()
            }
            reader.readAsArrayBuffer(file);
        })
    }

    const handleClick = async () => {
        if (!fileFingerprint) {
            await handleError("File báo cáo nghỉ phép và chấm công không được bỏ trống.");
            return
        }
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[1] = true;
            return newLoadings;
        });

        getBuffer(fileFingerprint).then(async (res) => {
            try {
                let fileName = fileFingerprint.name;
                const request = new ToolInputRequest();
                request.setFileName(fileName)
                request.setChunk(res)
                const stream = await client.preProcessTool(request, metadata);
                let fileContent = new Uint8Array(stream.getFileContent().length);
                fileContent.set(stream.getFileContent())
                saveByteArray(fileName, fileContent)

                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[1] = false;
                    return newLoadings;
                });
            } catch (err) {
                if (err instanceof RpcError && err.code === 16) {
                    handleError(err.message).then(res => {
                        localStorage.removeItem('token')
                        window.location.reload()
                    });
                } else {
                    handleError(err.message);
                }
                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[1] = false;
                    return newLoadings;
                });
            }
        })
    };

    return (
        <div className="App app-container">
            <Row>
                <Col span={24}>
                    <Card type="inner" title="Tải lên dữ liệu và click xử lý">
                        <Row>
                            <Col span={12}>
                                <label className="mr-10">File Báo Cáo Chấm Công</label>
                                <input type="file"
                                       accept=".xlsx, .xls, .csv"
                                       onChange={(e) => setFileFingerprint(e.target.files[0])}
                                />
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    loading={loadings[1]}
                                    onClick={handleClick}
                                >
                                    Xử lý
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}