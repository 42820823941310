/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: service_upload_file.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.pb = require('./service_upload_file_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.HrTimeSheetServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.HrTimeSheetServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FileUploadRequest,
 *   !proto.pb.FileUploadResponse>}
 */
const methodDescriptor_HrTimeSheetService_UploadFile = new grpc.web.MethodDescriptor(
  '/pb.HrTimeSheetService/UploadFile',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.pb.FileUploadRequest,
  proto.pb.FileUploadResponse,
  /**
   * @param {!proto.pb.FileUploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.FileUploadResponse.deserializeBinary
);


/**
 * @param {!proto.pb.FileUploadRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileUploadResponse>}
 *     The XHR Node Readable Stream
 */
proto.pb.HrTimeSheetServiceClient.prototype.uploadFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.HrTimeSheetService/UploadFile',
      request,
      metadata || {},
      methodDescriptor_HrTimeSheetService_UploadFile);
};


/**
 * @param {!proto.pb.FileUploadRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FileUploadResponse>}
 *     The XHR Node Readable Stream
 */
proto.pb.HrTimeSheetServicePromiseClient.prototype.uploadFile =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.HrTimeSheetService/UploadFile',
      request,
      metadata || {},
      methodDescriptor_HrTimeSheetService_UploadFile);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.SignInUserInput,
 *   !proto.pb.SignInUserResponse>}
 */
const methodDescriptor_HrTimeSheetService_SignInUser = new grpc.web.MethodDescriptor(
  '/pb.HrTimeSheetService/SignInUser',
  grpc.web.MethodType.UNARY,
  proto.pb.SignInUserInput,
  proto.pb.SignInUserResponse,
  /**
   * @param {!proto.pb.SignInUserInput} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.SignInUserResponse.deserializeBinary
);


/**
 * @param {!proto.pb.SignInUserInput} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.SignInUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.SignInUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.HrTimeSheetServiceClient.prototype.signInUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.HrTimeSheetService/SignInUser',
      request,
      metadata || {},
      methodDescriptor_HrTimeSheetService_SignInUser,
      callback);
};


/**
 * @param {!proto.pb.SignInUserInput} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.SignInUserResponse>}
 *     Promise that resolves to the response
 */
proto.pb.HrTimeSheetServicePromiseClient.prototype.signInUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.HrTimeSheetService/SignInUser',
      request,
      metadata || {},
      methodDescriptor_HrTimeSheetService_SignInUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.CalculatorRequest,
 *   !proto.pb.CalculatorResponse>}
 */
const methodDescriptor_HrTimeSheetService_CalculatorData = new grpc.web.MethodDescriptor(
  '/pb.HrTimeSheetService/CalculatorData',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.pb.CalculatorRequest,
  proto.pb.CalculatorResponse,
  /**
   * @param {!proto.pb.CalculatorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.CalculatorResponse.deserializeBinary
);


/**
 * @param {!proto.pb.CalculatorRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.CalculatorResponse>}
 *     The XHR Node Readable Stream
 */
proto.pb.HrTimeSheetServiceClient.prototype.calculatorData =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.HrTimeSheetService/CalculatorData',
      request,
      metadata || {},
      methodDescriptor_HrTimeSheetService_CalculatorData);
};


/**
 * @param {!proto.pb.CalculatorRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.CalculatorResponse>}
 *     The XHR Node Readable Stream
 */
proto.pb.HrTimeSheetServicePromiseClient.prototype.calculatorData =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/pb.HrTimeSheetService/CalculatorData',
      request,
      metadata || {},
      methodDescriptor_HrTimeSheetService_CalculatorData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.ToolInputRequest,
 *   !proto.pb.ToolResponse>}
 */
const methodDescriptor_HrTimeSheetService_PreProcessTool = new grpc.web.MethodDescriptor(
  '/pb.HrTimeSheetService/PreProcessTool',
  grpc.web.MethodType.UNARY,
  proto.pb.ToolInputRequest,
  proto.pb.ToolResponse,
  /**
   * @param {!proto.pb.ToolInputRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.pb.ToolResponse.deserializeBinary
);


/**
 * @param {!proto.pb.ToolInputRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.ToolResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.ToolResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.HrTimeSheetServiceClient.prototype.preProcessTool =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/pb.HrTimeSheetService/PreProcessTool',
      request,
      metadata || {},
      methodDescriptor_HrTimeSheetService_PreProcessTool,
      callback);
};


/**
 * @param {!proto.pb.ToolInputRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.ToolResponse>}
 *     Promise that resolves to the response
 */
proto.pb.HrTimeSheetServicePromiseClient.prototype.preProcessTool =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/pb.HrTimeSheetService/PreProcessTool',
      request,
      metadata || {},
      methodDescriptor_HrTimeSheetService_PreProcessTool);
};


module.exports = proto.pb;

