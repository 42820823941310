import * as XLSX from "xlsx";
import Papa from "papaparse";
import { v4 as uuidv4 } from 'uuid';
import {FileUploadRequest, MetaData, FileType, FileData, FileUpload, CalculatorRequest} from "../protobuf/pb/service_upload_file_pb";
import {HrTimeSheetServicePromiseClient} from "../protobuf/pb/service_upload_file_grpc_web_pb";
const client = new HrTimeSheetServicePromiseClient(process.env.REACT_APP_PROXY_HOST, null, null)
export async function  handleFileUpload(fileTakeBreak, fileFingerprint, metadata = {}){

    const id = uuidv4();

    return new Promise((resolve, reject) => {

        Promise.all([chunkFile(fileTakeBreak, FileType.FILE_TAKE_BREAK, id, metadata), chunkFile(fileFingerprint, FileType.FILE_FINGER_PRINT, id, metadata)])
            .then(res => {
                resolve(id)
            })
            .catch(err => {
                reject(err)})
    } )
}

export function saveByteArray(reportName, byte) {
    // const uint8Array = new Uint8Array(byte);
    var blob = new Blob([byte], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
}

const chunkFile = async (file, fileType, id, metadata) => {
    const CHUNK_SIZE = 1000;
    const listChunk = [];
    await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {type: 'array', cellDates:false, cellText:false, raw: true});
            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one you want to process
            const worksheet = workbook.Sheets[sheetName];
            const csv = XLSX.utils.sheet_to_csv(worksheet, {rawNumbers: true});
            const results = Papa.parse(csv, {header: true});
            let currentChunk = 0;
            let offset = 0;
            while (offset < results.data.length) {
                const chunk = results.data.slice(offset, offset + CHUNK_SIZE);
                // console.log("chunk: ", chunk);
                listChunk.push(chunk);
                offset += CHUNK_SIZE;
                currentChunk++;
            }
            resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    })

    return  new Promise((resolve, reject) => {
        const promises = [];
        let countFlag = 0;
        for (const i in listChunk) {
            let endRecord = i == listChunk.length - 1;
            promises.push(sendUploadFile(listChunk[i], endRecord, id, fileType, metadata))
        }

        Promise.all(promises).then(res => {
            for (const property in res) {
                const resF = res[property]
                resF.on('error', function (err) {
                    reject(err)
                    resF.cancel()
                });

                resF.on('data', function(response) {
                    countFlag++
                    if (countFlag == listChunk.length) {
                        resolve(res)
                    }
                });
            }
        }).catch(err => reject(err))
    })
}

async function sendUploadFile(chunkFile, end = false, name, type, metadata) {

    let dataInput = convertData(chunkFile);

    const request = new FileUploadRequest();
    const fileUpload = new FileUpload();
    const metaData = new MetaData();
    metaData.setName(name);
    metaData.setType(type);
    fileUpload.setMetadata(metaData);


    // Set the content of the file
    const fileData = new FileData();
    fileData.setEndrecord(end);
    fileData.setDatacontent(JSON.stringify(dataInput));
    fileUpload.setContent(fileData);

    request.setFileUpload(fileUpload);

    const stream = await client.uploadFile(request, metadata);
    return stream;
}

function convertData(chunkFile) {
    return chunkFile.map((item) => {
        return Object.values(item);
    })
}

export async function downloadFile(id, timeCondition) {
    const request = new CalculatorRequest();
    request.setId(id)
    request.setTimeCondition(timeCondition)
    const stream = await client.calculatorData(request, {});
    return stream;
}

export function mergeUint8Arrays(array1, array2) {
    // Create a new Uint8Array with the combined length
    const mergedArray = new Uint8Array(array1.length + array2.length);

    // Copy the elements from array1 into the mergedArray
    mergedArray.set(array1);

    // Copy the elements from array2 into the mergedArray
    mergedArray.set(array2, array1.length);

    return mergedArray;
}