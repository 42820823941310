import React, { useState } from 'react';
import {downloadFile, handleFileUpload, mergeUint8Arrays, saveByteArray} from "../../services/uploadFileStream";
import Swal from 'sweetalert2';
import useToken from "./useToken";
import {Button, Col, Row, Upload, Card, TimePicker} from 'antd';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';


import { DownloadOutlined } from '@ant-design/icons';
import './Mainapp.css';
import {RpcError} from "grpc-web";

export default function MainApp() {
    const [fileTakeBreak,setFileTakeBreak] = useState(null);
    const [fileFingerprint,setFileFingerprint] = useState(null);
    const { token, setToken } = useToken();
    const [loadings, setLoadings] = useState([]);
    const [timeCondition, setTimeCondition] = useState(dayjs('6:30:00', 'HH:mm:ss'));
    const metadata = {"authorization": "Bearer " + token};

    dayjs.extend(customParseFormat);
    const handleError = (error) => {
        return  Swal.fire({
            title: 'Error',
            text: error,
            icon: 'error',
            confirmButtonText: 'OK',
        });
    };

    const handleClick = () => {
        const timeString = `${timeCondition.hour()}:${timeCondition.minute()}:${timeCondition.second()}`;

        if(timeCondition.hour() === 0 && timeCondition.minute() === 0 && timeCondition.second() === 0) {
            handleError("Không được bỏ trống thời gian.");
            return
        }

        if(!fileTakeBreak || !fileFingerprint) {
            handleError("File báo cáo nghỉ phép và chấm công không được bỏ trống.");
            return
        }
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[1] = true;
            return newLoadings;
        });
        handleFileUpload(fileTakeBreak, fileFingerprint, metadata)
            .then(async res => {
                setTimeout(async () => {
                    // start send download request
                    const download = await downloadFile(res, timeString);
                    let fileContent = [];
                    let fileName = "";
                    download.on('data', function (response) {
                        fileName = response.getFileName()
                        fileContent = mergeUint8Arrays(fileContent, response.getChunk())
                    });

                    download.on('end', function (end) {
                        // stream end signal
                        saveByteArray(fileName, fileContent)
                    });

                    download.on('status', function (status) {
                        if(status.code) {
                            handleError(status.details);
                        }
                    });

                    setLoadings((prevLoadings) => {
                        const newLoadings = [...prevLoadings];
                        newLoadings[1] = false;
                        return newLoadings;
                    });
                }, 2000)

            })
            .catch(err => {
                if (err instanceof RpcError && err.code === 16) {
                    handleError(err.message).then(res => {
                        localStorage.removeItem('token')
                        window.location.reload()
                    });
                } else {
                    handleError(err.message);
                }
                setLoadings((prevLoadings) => {
                    const newLoadings = [...prevLoadings];
                    newLoadings[1] = false;
                    return newLoadings;
                });
            })
    };


    return (
        <div className="App app-container">
          <Row>
              <Col span={24}>
                <Card type="inner" title="Tải lên dữ liệu và click xử lý">
                    <Row>
                        <Col span={8} className={"time_condition"}>
                            <label className="mr-10">Điều kiện thời gian: </label>
                            <TimePicker value={timeCondition} onChange={(time) => setTimeCondition(time)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <label className="mr-10">File Báo Cáo Nghỉ Phép</label>
                            <input type="file"
                                   accept=".xlsx, .xls, .csv"
                                   onChange={(e) => setFileTakeBreak(e.target.files[0])}
                            />
                        </Col>
                        <Col span={8}>
                            <label className="mr-10">File Báo Cáo Chấm Công</label>
                            <input type="file"
                                   accept=".xlsx, .xls, .csv"
                                   onChange={(e) => setFileFingerprint(e.target.files[0])}
                            />
                        </Col>
                        <Col span={8}>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                loading={loadings[1]}
                                onClick={handleClick}
                            >
                                Xử lý
                            </Button>
                        </Col>
                    </Row>

              </Card>
              </Col>
          </Row>
        </div>
    );
}