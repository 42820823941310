import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import MainApp from "./components/App/MainApp";
import PreProcessTool from "./components/PreProcessTool/PreProcessTool";
import Login from './components/Login/Login';
import useToken from './components/App/useToken';


function App() {
    const { token, setToken } = useToken();
    if(!token) {
        return <Login setToken={setToken} />
    }
    return (
      <div className="wrapper">
          <BrowserRouter>
              <Routes>
                  <Route exact path="/" element={<MainApp />} />
                  <Route exact path="/pre-process-tool" element={<PreProcessTool />} />
              </Routes>
          </BrowserRouter>
      </div>

    );
}

export default App;
