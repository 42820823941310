import React, { useState } from 'react';
import {  Button, Form, Input  } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import {SignInUserInput} from "../../protobuf/pb/service_upload_file_pb";
import {HrTimeSheetServicePromiseClient} from "../../protobuf/pb/service_upload_file_grpc_web_pb";
import Swal from "sweetalert2";
import './Login.css';

const handleError = (error) => {
    return  Swal.fire({
        title: 'Error',
        text: error,
        icon: 'error',
        confirmButtonText: 'OK',
    });
};
async function loginUser(credentials) {
    const client = new HrTimeSheetServicePromiseClient(process.env.REACT_APP_PROXY_HOST, null, null)
    const request = new SignInUserInput();
    request.setEmail(credentials?.username);
    request.setPassword(credentials?.password);

    return await client.signInUser(request, {"service-name": "SignInUser"});
}

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        // e.preventDefault();
        try {
            const data = await loginUser({
                username,
                password
            });
            setToken(data.getAccessToken());
            window.location.href = '/';
        } catch (e) {
            await handleError(e.message)
        }

    }

    return(
        <div className="login-wrapper">
            <Form onFinish={handleSubmit}
                  name="normal_login"
                  className="login-form">

                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Username"
                        onChange={e => setUserName(e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Log in
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}